import { configureStore } from "@reduxjs/toolkit";
import rightSlice from "./rightSlice";
import accountSlice from "./accountSlice";

const store = configureStore({
  reducer: {
    rights: rightSlice,
    account: accountSlice
  },
});

export default store;
