import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Breadcrumb,
  Table,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import axios from "axios";
import { API_URL } from "../constant";
import { handleGetRole } from "../shared/api";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
function AssignRights() {
  const navigate = useNavigate();

  const [rightScreens, setrightScreens] = useState([]);
  const [rightScreensrole, setrightScreensrole] = useState([]);
  const [isRight, setisRight] = useState(false);

  const [roles, setroles] = useState([]);
  const [selectedRole, setselectedRole] = useState({ id: null, name: null });
  const [signRigts, setsignRigts] = useState([]);

  const [selectedRights, setSelectedRights] = useState();
  const [loader, setloader] = useState(true);

  useEffect(() => {
    handleGetRole()
      .then((res) => {
        // setselectedRole({
        //   id: res.data.data[0].id,
        //   name: res.data.data[0].name,
        // });
        setroles(res.data.data);
      })
      .catch((error) => { });

    axios
      .get(API_URL + `api/SuperAdmin/getAllScreen`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setloader(false);
        setrightScreens(res.data.data);
        res.data.data.map((x) => {
          setrightScreensrole((prev) => {
            return [
              ...prev,
              {
                screenId: x.id,
                isAdd: false,
                isUpdate: false,
                isDelete: false,
                isView: false
              },
            ];
          });
          setsignRigts((prev) => {
            return [
              ...prev,
              {
                screenId: x.id,
                isAdd: false,
                isUpdate: false,
                isDelete: false,
                isView: false
              },
            ];
          });
        });
      })
      .catch(() => {
        setloader(false);
      });
  }, []);

  useEffect(() => {
    if (selectedRole.id) {
      setloader(true);
      axios
        .get(
          `${API_URL}api/SuperAdmin/GetAssignRights?RoleId=${selectedRole.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("Token")}`,
            },
          }
        )
        .then((res) => {
          const rights = res.data.data.rights;
          const checked = rightScreens.map((screen) => {
            const rightsForScreen = rights.filter(
              (right) => right.screenId === screen.id
            );
            const isAdd = rightsForScreen.some((right) => right.isAdd);
            const isUpdate = rightsForScreen.some((right) => right.isUpdate);
            const isDelete = rightsForScreen.some((right) => right.isDelete);
            const isView = rightsForScreen.some((right) => right.isView);
            return {
              screenId: screen.id,
              isAdd,
              isUpdate,
              isDelete,
              isView
            };
          });
          const hasRights = checked.some(
            (right) => right.isAdd || right.isUpdate || right.isDelete
          );
          setisRight(hasRights);
          setsignRigts(checked);
          setloader(false);
        })
        .catch((err) => {
          setloader(false);
        });
    }
  }, [selectedRole.id]);

  const handleCheckboxChange = (screenId, checkboxName, isChecked) => {
    let updatedUsers;

    const index = rightScreensrole.findIndex((x) => x.screenId === screenId);
    let tempArr = rightScreensrole;
    if (checkboxName === "isDelete") {
      tempArr[index].isDelete = isChecked;
      updatedUsers = signRigts.map((user) => {
        if (user.screenId == screenId) {
          return { ...user, isDelete: isChecked }; // Use the spread operator to create a new object with the updated age
        }
        return user; // Return the original object if the id doesn't match
      });
    } else if (checkboxName === "isUpdate") {
      tempArr[index].isUpdate = isChecked;
      updatedUsers = signRigts.map((user) => {
        if (user.screenId == screenId) {
          return { ...user, isUpdate: isChecked }; // Use the spread operator to create a new object with the updated age
        }
        return user; // Return the original object if the id doesn't match
      });
    } else {
      tempArr[index].isAdd = isChecked;
      updatedUsers = signRigts.map((user) => {
        if (user.screenId == screenId) {
          return { ...user, isAdd: isChecked }; // Use the spread operator to create a new object with the updated age
        }
        return user; // Return the original object if the id doesn't match
      });
    }
    const hasRights = updatedUsers.some(
      (right) => right.isAdd || right.isUpdate || right.isDelete
    );
    setisRight(hasRights);
    setsignRigts(updatedUsers);

    let obj = {
      roleId: selectedRole.id,
      rightList: updatedUsers,
    };

    setSelectedRights(obj);
  };
  const handleUpdateRole = () => {

    if (!selectedRole.id) {
      toast.info("Please Select any Role");
      return;
    }
    setloader(true);
    axios
      .post(API_URL + `api/SuperAdmin/assignrights`, selectedRights, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setloader(false);
        toast.success("Role has been assigned");
        navigate("/SubadminManagement")
      })
      .catch((e) => {
        setloader(false);

      });
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/SubadminManagement">
                  Subadmin
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Assign Rights</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>Assign Rights</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form style={{ maxWidth: "900px" }}>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Role NAME</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Role Name" />
                                </Form.Group> */}
                <Form.Label>Select Role Name</Form.Label>
                {/* <Dropdown className="form-control" onSelect={(e) => {
                                    const selectedRole = roles.find((role) => role.name === e);
                                    setselectedRole({ id: selectedRole.id, name: selectedRole.name });
                                }}>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        {selectedRole?.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu variant="dark">
                                        {roles?.map((roleData) => (
                                            <>
                                                <Dropdown.Item key={roleData.id} eventKey={roleData.name}  >

                                                    {roleData.name}
                                                </Dropdown.Item>

                                            </>

                                        ))}


                                    </Dropdown.Menu>
                                </Dropdown> */}
                <Form.Select
                  name="role"
                  onChange={(e) => {


                    setselectedRole({
                      id: e.target.value,
                      name: selectedRole?.name,
                    });
                  }}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="" disabled selected>
                    Choose Role
                  </option>

                  {roles.map((roleData) => (
                    <option value={roleData.id} key={roleData.id}>
                      {roleData.name}
                    </option>
                  ))}
                </Form.Select>

                {/* <h5><b>SELECT RIGHTS</b></h5> */}
                <div className="table-container">
                  <div className="table-container-inner">
                    <Table>
                      <thead>
                        <tr>
                          <th>SELECT RIGHTS</th>

                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>

                          <td>Add</td>
                          <td>Update</td>
                          <td>Delete</td>
                        </tr>

                        {rightScreens?.map((screenData) => (
                          <tr key={screenData.id}>
                            <td>{screenData.name}</td>

                            <td>
                              <Form.Check
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    screenData.id,
                                    "isAdd",
                                    e.target.checked,
                                    screenData.id
                                  )
                                }
                                type="checkbox"
                                label=""
                                checked={
                                  signRigts.some(
                                    (data) =>
                                      data.isAdd &&
                                      data.screenId === screenData.id
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td>
                              <Form.Check
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    screenData.id,
                                    "isUpdate",
                                    e.target.checked
                                  )
                                }
                                type="checkbox"
                                label=""
                                checked={
                                  signRigts.some(
                                    (data) =>
                                      data.isUpdate &&
                                      data.screenId === screenData.id
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td>
                              <Form.Check
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    screenData.id,
                                    "isDelete",
                                    e.target.checked
                                  )
                                }
                                type="checkbox"
                                label=""
                                checked={
                                  signRigts.some(
                                    (data) =>
                                      data.isDelete &&
                                      data.screenId === screenData.id
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <ul className="half-list">
                  <li>
                    <Link className="reg-btn w-100 h-10" style={{ padding: '15px', fontSize: '16px' }} onClick={() => {
                      handleUpdateRole();
                    }}>

                      Update
                    </Link>
                  </li>
                  <li>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Button>
                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default AssignRights;
