import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../src/login.scss";
import { Form, Button } from "react-bootstrap";

import bgimg from "../src/assets/images/bg.png";
import logo from "../src/assets/images/login-logo.png";
import { API_URL } from "./constant";
import CustomLoader from "./Components/CustomeLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { givenRights } from "./redux/rightSlice";
import localforage from "localforage";
import { getAccount } from "./redux/accountSlice";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setloader] = useState(false);
  const [DataErrors, setDataErrors] = useState({});

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    if(e.target.value){
      setDataErrors((prevState) => ({
        ...prevState,
        email: '',
      }));
    }
  };
  const passwordChange = (e) => {
    if(e.target.value){
      setDataErrors((prevState) => ({
        ...prevState,
        password: '',
      }));
    }
  };

  useEffect(()=>{

    if(localStorage.getItem("Token"))
    {
     navigate('/CouponManagment')
    }
           
   },[])
  
  const handleLoginSubmit = (e) => {e.preventDefault();
    if(!e.target.elements.email.value||!e.target.elements.password.value){ 
      if(!e.target.elements.email.value){
        
        setDataErrors((prevState) => ({
          ...prevState,
          email: 'Email is required.',
        }));
      }else if(!e.target.elements.password.value){
        
        setDataErrors((prevState) => ({
          ...prevState,
          password: 'Password is required.',
        }));
      }else {
        setError({})
      }     
      return
    }
   
    setError(null);
    
    setloader(true);
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    const payload = {
      UserInfo: email,
      password: password,
    };
    localStorage.setItem("email", email);
    axios
      .post(API_URL + `api/SuperAdmin/Adminlogin`, payload)
      .then((res) => {
        if (res.data?.error?.errorCode === "Blocked") {
          setError("Account Blocked! Contact Admin.");
          toast.error("Account Blocked! Contact Admin.");
          setloader(false);
        } else if (res.data.error) {
          setError("Invalid email or password");
          setloader(false);
          return;
        } else if (res.data.data) {
          if (res.data.data.rights.length < 1) {
            setError("No rights have been assigned! Contact Admin");
            toast.warn("No rights have been assigned! Contact Admin");
            setloader(false);
            return
          }
          localStorage.setItem("Token", res.data.data.accessToken);
          localforage.setItem("Rights", res.data.data.rights);

          localStorage.setItem("isAddCollection", res.data.data.userInfo.isAddCollection);
          localStorage.setItem("accountType", res.data.data.userInfo.accountType);
          // localforage.setItem("Account", res.data.data.userInfo)

          setloader(false);
          dispatch(givenRights(res.data.data.rights));
          dispatch(getAccount(res.data.data.userInfo))


          if (
            res.data.data.rights[0]?.screenName.toLowerCase() ===
            "coupon management"
          ) {
            navigate(`/CouponManagment`);
          } else if (
            res.data.data.rights[0]?.screenName.toLowerCase() ===
            "partner management"
          ) {
            navigate("/PartnerManagement");
          } else if (
            res.data.data.rights[0]?.screenName.toLowerCase() ===
            "nft collection management"
          ) {
            navigate(`/NFTCollectionManagement`);
          } else if (
            res.data.data.rights[0]?.screenName.toLowerCase() ===
            "subadmin management"
          ) {
            navigate(`/SubadminManagement`);
          }
        }
      })
      .catch((error) => {
        setloader(false);
      });
  };

  return (
    <>
      <CustomLoader isLoading={loader} />
      <main className="login-main">
        <div className="login-right">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${bgimg})` }}
          ></div>
          <div className="login-right-inner">
            <h1>Welcome</h1>
            <h3>Login to access your admin account</h3>
          </div>
        </div>
        <div className="login-left">
          <Link className="login-logo" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <div className="login-left-inner">
            <h2>Login</h2>
            <Form autoComplete="off" onSubmit={(e) => handleLoginSubmit(e)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type="email"
                    name="email"
                    className="email-input"
                    placeholder="youremail123@gmail.com"
                    autoComplete="off"
                    onChange={handleEmailChange}
                  />
                  <Button className="input-btn">
                    <i className="fa fa-envelope  "></i>
                  </Button>
                </div>
                {DataErrors.email && <p className="error-login"> {DataErrors.email}</p>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Your Password"
                    autoComplete="new-password"
                    onChange={passwordChange}
                  />
                  <Button className="input-btn" onClick={handleTogglePassword}>
                    <i
                      className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                    ></i>
                  </Button>
                </div>
                {DataErrors.password && <p className="error-login"> {DataErrors.password}</p>}
              </Form.Group>
              {error  && <p className="error-login"> {error}</p>}
              {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <Form.Control type="password" placeholder="Your Password" />
                                    <Button className='input-btn'><i className='fa fa-eye'></i></Button>
                                </div>
                            </Form.Group> */}
              {/* <Link to="/SubadminManagement" className='submit-btn'>Login</Link> */}

              <Button
                disabled={loader}
                className="submit-btn mt-4"
                type="submit"
              >
                Login
              </Button>

              {/*  <Form.Control type="submit" className='submit-btn' value="Login" /> */}
            </Form>
          </div>
        </div>
      </main>
    </>
  );
}
export default Login;
