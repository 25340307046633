
import axiosApi from '../axiosApi';

import { API_URL } from "../constant";

export const handleGetRole = async () => {
  //This APi used to get all role
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/getroles`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};

export const getAllAppUser = async () => {
  //This APi used to get all role
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/GetAllAppUser`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};



export const handleGetCollectionCategory = async () => {
  //Get All Collection Category
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetCategoriesList`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const distributeNft = async (id, copies) => {
  //Get All Collection Category
  return new Promise((resolve, reject) => {
    axiosApi
      .post(API_URL + `api/Nft/UpdateNFTCopy`, {
        "nftId": id,
        "nftCopy": copies
      }, {
        timeout: "10000ms"
      })
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetAllCollection = async (currentPage, searched, checked) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Nft/GetAllCollections?PageSize=12&CurrentPage=${currentPage}&Search=${searched}&approveStatus=${checked}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetCollectionbyId = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetNftCollectionById?CollectionId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetCollectionbyApproved = async (approved) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetAllCollections?approveStatus=${approved}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};


export const handleGetUserList = async (currentPage, userType, searched) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/SuperAdmin/GetUserList?PageSize=10&CurrentPage=${currentPage}&Search=${searched}&IsPartner=${userType}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetNftByCollectionId = async (id, currentPage, searched, NFTFilters, IsTireBase, IsProduct,IsMarket) => {
  const pageSize = 12
  return new Promise((resolve, reject) => {
    let endpoint
    if (NFTFilters?.length > 0) {
      endpoint = `api/Nft/GetAllNftByCollectionId?collectionId=${id}&PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searched}&filterStatus=${NFTFilters}&IsTireBase=${IsTireBase}&IsProductBase=${IsProduct}&IsMarketplace=${IsMarket}`

    }
    else {
      endpoint = `api/Nft/GetAllNftByCollectionId?collectionId=${id}&PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searched}&IsTireBase=${IsTireBase}&IsProductBase=${IsProduct}&IsMarketplace=${IsMarket}`
    }
    axiosApi
      .get(
        API_URL + endpoint,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetNftById = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetMyNftById?nftId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetHistory = async (currentPage, id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Nft/GetNftActivityHistory?PageSize=100&CurrentPage=${currentPage}&nftId=${id}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleBlockedUser = async (id, activation) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/SuperAdmin/UserActivation?adminUserId=${id}&activation=${activation}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetUserById = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/SuperAdmin/GetUserById?id=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetNFTBidHistory = async (id, currentPage) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(API_URL + `api/Nft/GetMyAllBidByNftId?NftId=${id}&PageSize=10&CurrentPage=${currentPage}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteUser = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/SuperAdmin/DeleteUser?UserId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteCollection = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Nft/DeleteCollection?collectionId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteNFT = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Nft/DeleteNFT?NftId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleDeleteCoupon = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .delete(API_URL + `api/Coupon/DeleteCoupon?CouponId=${id}`)
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};

export const handleGetAllCoupons = async (currentPage, searched, IsTireBase, IsProduct,IsMarket) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Coupon/GetAllCoupon?PageSize=10&CurrentPage=${currentPage}&Search=${searched}&IsTireBase=${IsTireBase}&IsProductBase=${IsProduct}&IsPromotion=${IsMarket}`
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetCouponNFT = async () => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Coupon/GetAllNft`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
export const handleGetCouponbyId = async (id) => {
  return new Promise((resolve, reject) => {
    axiosApi
      .get(
        API_URL +
        `api/Coupon/GetCouponById?Id=${id}`,
    
      )
      .then((x) => {
        resolve(x);
      })
      .catch((x) => {
        reject(x);
      });
  });
};
