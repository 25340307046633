import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { useEffect, useState } from "react";
import { handleGetCouponNFT, handleGetCouponbyId, handleGetRole } from "../shared/api";
import axios from "axios";
import { API_URL } from "../constant";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
import CheckFileSize from "./controllers";
function AddNewCoupon() {
  const navigate = useNavigate();
  const { id } = useParams()
  const [loader, setloader] = useState(true);
  const [uploadImage, setuploadImage] = useState(null);
  const [couponData, setcouponData] = useState({
    name: null,
    image: null,
    expiration: null,
    redemption: null,
    nftid: null,
    status: null,
  });
  const [Err, setErr] = useState({
    name: null,
    image: null,
    expiration: null,
    redemption: null,
    nftid: null,
    status: null,
  });
  const [coupnNFtData, setcoupnNFtData] = useState([]);

  useEffect(() => {
    handleGetCouponNFT()
      .then((res) => {
        setcoupnNFtData(res.data.data);
        setloader(false);
      })
      .catch((error) => {
        setloader(false);
      });
  }, []);
  useEffect(() => {
    if (id) {
      handleGetCouponbyId(id).then((res) => {
        const data = res.data.data;
        setcouponData({
          name: data.name,
          image: data.image,
          expiration: data.expirationDate.split("T")[0],
          redemption: data.redemptionDate.split("T")[0],
          nftid: data.nFtId,
          status: data.status,
        })

      })
    }

  }, [id])


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      setErr((prevState) => ({
        ...prevState,
        image: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if(CheckFileSize(file.size)){
        return
      }
      setcouponData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setuploadImage(file);
      setErr((prevState) => ({
        ...prevState,
        image: null,
      }));
    }
  };
  const handleCouponSubmit = (e) => {
    e.preventDefault();

    setloader(true);
    const bodyFormdata = new FormData();

    if (id) {

      bodyFormdata.append("Id", id);
    } else {
      bodyFormdata.append("Id", 0);

    }
    bodyFormdata.append("Name", couponData.name);
    bodyFormdata.append("NFtId", couponData.nftid);
    bodyFormdata.append("ExpirationDate", couponData.expiration);
    bodyFormdata.append("RedemptionDate", couponData.redemption);
    bodyFormdata.append("Image", couponData.image);
    bodyFormdata.append("Status", "Pending");
    axios
      .post(API_URL + `api/Coupon/AddUpdateCoupon`, bodyFormdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        toast.success("Coupon Added Successfully");
        navigate(`/CouponManagment`);
        setloader(false);
      })
      .catch((erro) => {
        setloader(false);
      });
  };
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/SubadminManagement">
                  NFT Coupon Management
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Add New Coupon</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>Add New Coupon</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form
                style={{ maxWidth: "900px" }}
                onSubmit={(e) => handleCouponSubmit(e)}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Coupon Name</Form.Label>
                  <Form.Control
                    required
                    value={couponData.name}
                    onChange={(e) => {
                      setcouponData((prevState) => ({
                        ...prevState,
                        name: e.target.value.trimStart(),
                      }));
                    }}
                    type="text"
                    placeholder="Enter Coupon Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Upload Photo</Form.Label>
                  <div className="input-wrapper">
                    <Link
                      className="submit-btn"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      Upload image
                    </Link>
                    <input
                      type="file"
                      id="file-input"
                      accept=" image/jpeg, image/png"
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </div>
                  {Err.image && <p className="error-msg">{Err.image}</p>}
                  {(uploadImage || couponData?.image) && (
                    <>

                      {uploadImage ? (
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={URL.createObjectURL(uploadImage)}
                          alt="logofig"
                        />
                      ) : (
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={`${API_URL}${couponData.image}`}
                          alt="logofig"
                        />
                      )}
                    </>
                  )}

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>expiration date </Form.Label>
                  <Form.Control
                    value={couponData.expiration}
                    required
                    onChange={(e) => {
                      setcouponData((prevState) => ({
                        ...prevState,
                        expiration: e.target.value,
                      }));
                    }}
                    type="date"
                    placeholder="expiration date"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Redemption date </Form.Label>
                  <Form.Control
                    value={couponData.redemption}
                    required
                    onChange={(e) => {
                      setcouponData((prevState) => ({
                        ...prevState,
                        redemption: e.target.value,
                      }));
                    }}
                    type="date"
                    placeholder="expiration date"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Choose Collection</Form.Label>
                  <Form.Select
                    value={couponData?.nftid}
                    required
                    onChange={(e) => {
                      setErr((prevState) => ({
                        ...prevState,
                        nftid: "",
                      }));
                      setcouponData((prevState) => ({
                        ...prevState,
                        nftid: e.target.value,
                      }));
                    }}
                    name="collection"
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option value="" disabled selected>
                      Select an option
                    </option>

                    {coupnNFtData.map((nftData) => (
                      <option value={nftData.id} key={nftData.id}>
                        {nftData.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <ul className="half-list">
                  <li>
                    <Button type="submit" className="reg-btn w-100 big">
                      {id ? "Update" : "Add New"}   Coupon
                    </Button>
                    {/* <Link to="/" className='reg-btn w-100 big'>Add New Partner</Link> */}
                  </li>
                  <li>
                    <Button
                      onClick={() => navigate(-1)}
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Button>

                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default AddNewCoupon;
