import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Button, InputGroup } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { useEffect, useState } from "react";
import { handleGetRole } from "../shared/api";
import axios from "axios";
import { API_URL } from "../constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

function AddNewPartner() {
  const navigate = useNavigate();
  const [role, setrole] = useState([]);
  const [partnerData, setpartnerData] = useState({
    name: "",
    lastName:"",
    email: "",
    permission: "",
    nftCollection: true,
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [errorMessage, seterrorMessage] = useState({
    nameErr: "",
    LastnameErr: "",
    emailErr: "",
    permissionErr: "",
    passwordErr: "",
  });
  useEffect(() => {
    handleGetRole()
      .then((res) => {
        setrole(res.data.data);
      })
      .catch((error) => { });
  }, []);
  const handlePasswordChange = (e) => {
    const password = e.target.value.trimStart();
    setpartnerData((prevState) => ({
      ...prevState,
      password,
    }));

    if (!strongPasswordRegex.test(password)) {
      seterrorMessage((prevState) => ({
        ...prevState,
        passwordErr:
          "Password should be at least 8 characters long and contain at least one letter, one number, and one symbol.",
      }));
    } else {
      seterrorMessage((prevState) => ({
        ...prevState,
        passwordErr: "",
      }));
    }
  };
  function validateEmail(email) {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  }

  const handlePartnerSub = (e) => {
    e.preventDefault();

    const permission = e.target.permission.value;
    const nftCollection = e.target.nftCollection.value === "true";
    let temp = 0;
    if (!partnerData.name) {
      seterrorMessage((prevState) => ({
        ...prevState,
        nameErr: "Name Required",
      }));
      temp++;
    }
    if (!partnerData.lastName) {
      seterrorMessage((prevState) => ({
        ...prevState,
        LastnameErr: "Name Required",
      }));
      temp++;
    }
    if (!partnerData.email) {
      seterrorMessage((prevState) => ({
        ...prevState,
        emailErr: "Email Required",
      }));
      temp++;
    }
    if (errorMessage.passwordErr) {
      temp++;
    }
    if (!partnerData.password) {
      seterrorMessage((prevState) => ({
        ...prevState,
        passwordErr: "Password Required",
      }));
      temp++;
    }
    if (!permission) {
      seterrorMessage((prevState) => ({
        ...prevState,
        permissionErr: "Role Required",
      }));
      temp++;
    }

    if (temp > 0) {
      return;
    }

    const bodyFromData = new FormData();

    bodyFromData.append("Id", 0);
    bodyFromData.append("Address", null);
    bodyFromData.append("FirstName", partnerData.name);
    bodyFromData.append("LastName", partnerData.lastName);
    bodyFromData.append("Email", partnerData.email);
    bodyFromData.append("Password", partnerData.password);
    bodyFromData.append("ProfileImage", null);
    bodyFromData.append("ProfilImage", null);
    bodyFromData.append("Bio", null);
    bodyFromData.append("TwitterLink", null);
    bodyFromData.append("InstagramLink", null);
    bodyFromData.append("RoleId", permission);
    bodyFromData.append("RoleName", null);
    bodyFromData.append("AccountType", 4);
    bodyFromData.append("IsAddCollection", nftCollection);

    axios
      .post(API_URL + `api/SuperAdmin/SaveUser`, bodyFromData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        navigate("/PartnerManagement");
        toast.success("New Partner Added Successfully");
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error("Email Already Exist");
          seterrorMessage((prevState) => ({
            ...prevState,
            emailErr: "Same Email Already Exist",
          }));
        }
      });
  };
  return (
    <>
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/SubadminManagement">
                  Subadmin
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Add New Partner</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>Add New Partner</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form
                onSubmit={(e) => handlePartnerSub(e)}
                style={{ maxWidth: "900px" }}
              >
                <Form.Group className="mb-3" controlId="partnerName">
                  <Form.Label>FIRST NAME</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setpartnerData((prevState) => ({
                        ...prevState,
                        name: e.target.value.trimStart(),
                      }));
                      seterrorMessage((prevState) => ({
                        ...prevState,
                        nameErr: "",
                      }));
                    }}
                    value={partnerData.name}
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                  {errorMessage.nameErr && (
                    <p className="error-msg">{errorMessage.nameErr}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="partnerName">
                  <Form.Label>LAST NAME</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setpartnerData((prevState) => ({
                        ...prevState,
                        lastName: e.target.value.trimStart(),
                      }));
                      seterrorMessage((prevState) => ({
                        ...prevState,
                        LastnameErr: "",
                      }));
                    }}
                    value={partnerData.lastName}
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                  {errorMessage.LastnameErr && (
                    <p className="error-msg">{errorMessage?.LastnameErr}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>EMAIL</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      const email = e.target.value.trimStart();
                      setpartnerData((prevState) => ({
                        ...prevState,
                        email: email,
                      }));
                      if (!validateEmail(email)) {
                        seterrorMessage((prevState) => ({
                          ...prevState,
                          emailErr: "Please enter a valid email address",
                        }));
                      } else {
                        seterrorMessage((prevState) => ({
                          ...prevState,
                          emailErr: "",
                        }));
                      }
                    }}
                    name="email"
                    value={partnerData.email}
                    type="email"
                    placeholder="Enter Email"
                  />

                  {errorMessage.emailErr && (
                    <p className="error-msg">{errorMessage.emailErr}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    className="form-control"
                    name="permission"
                    aria-label="Select role"
                  >
                    <br />
                    <option value="" disabled selected>
                      Choose Option
                    </option>

                    {role?.map((roleData) => (
                      <option value={roleData.id} key={roleData.id}>
                        {roleData.name}
                      </option>
                    ))}
                  </Form.Select>

                  {errorMessage.permissionErr && (
                    <p className="error-msg">{errorMessage.permissionErr}</p>
                  )}
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>PERMISSIONS</Form.Label>
                                    <Form.Control type="text" placeholder="Enter PERMISSIONS" />
                                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>NFT COLLECTION</Form.Label>

                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="true"
                      name="nftCollection"
                      id="trueRadio"
                      defaultChecked={true}
                      value={true}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="false"
                      name="nftCollection"
                      id="falseRadio"
                      defaultChecked={false}
                      value={false}
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  style={{ position: "relative" }}
                  className="mb-5"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Password</Form.Label>
                  <div className="button-input-container">
                    <Form.Control
                      onChange={handlePasswordChange}
                      value={partnerData.password}
                      name="password"
                      // type="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                    />

                    <Button
                      variant="light"
                      onClick={handleTogglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </Button>
                  </div>

                  {errorMessage.passwordErr && (
                    <p className="error-msg">{errorMessage.passwordErr}</p>
                  )}
                </Form.Group>
                <ul className="half-list">
                  <li>
                    <Button type="submit" className="reg-btn w-100 big">
                      Add New Partner
                    </Button>
                    {/* <Link to="/" className='reg-btn w-100 big'>Add New Partner</Link> */}
                  </li>
                  <li>
                    <Button
                      onClick={() => navigate(`/PartnerManagement`)}
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Button>

                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default AddNewPartner;
