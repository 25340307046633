import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Pagination } from "react-pagination-bar";
import OGBXLoader from "../../src/assets/images/Loader.gif"
import NFTCollectionPost from "../Components/NFTCollectionPost";
import { handleGetAllCollection, handleGetCollectionbyApproved } from "../shared/api";
import CustomLoader from "../Components/CustomeLoader";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { Searchbar } from "../Components/Searchbar";
function NFTCollectionManagement() {
  const { rightAccess } = useSelector(rightSelector);
  const [loader, setloader] = useState(false);
  const [collectionsData, setcollectionsData] = useState([]);
  const [orginalData, setorginalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [checked, setchecked] = useState(null);
  const [searchedString, setsearchedString] = useState('');

  useEffect(() => {
    if (searchedString || checked) {
      setloader(true)
      handleGetAllCollection(currentPage, searchedString, checked == 1 ? 'Approved' : checked == 2 ? 'Unapproved' : '')
        .then((res) => {

          setcollectionsData(res.data.data);
          setorginalData(res.data.data)
          setTotalItems(res.data.totalItems);
          setloader(false)
        })
        .catch((err) => { setloader(false) });
    }
    else {
      getAllColl()
    }
  }, [currentPage, checked]);

  useEffect(() => {
    if (searchedString?.length == 0) {
      getAllColl()
    }
  }, [searchedString]);

  const getAllColl = () => {
    setloader(true);
    handleGetAllCollection(currentPage, "", "")
      .then((res) => {


        setTotalItems(res.data.totalItems);
        setcollectionsData(res.data.data);
        setorginalData(res.data.data)
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  }

  const handleFilter = (isApproved) => {
    setloader(true);

    handleGetCollectionbyApproved(isApproved)
      .then((res) => {
        setTotalItems(res.data.totalItems);
        setcollectionsData(res.data.data);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  }

  const handleSearch = (e) => {
    setloader(true);
    handleGetAllCollection(1, searchedString, '')
      .then((res) => {
        setTotalItems(res.data.totalItems);
        setcollectionsData(res.data.data);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const dataAfterDelete = (id) => {
    setcollectionsData((prev) => [
      ...collectionsData?.filter((x) => x.id !== id),
    ]);
  };

  const dataAfterApprove = (id, approve) => {
    const updatedCollection = collectionsData.map((collection) => {
      if (collection.id == id) {
        return { ...collection, isApproved: approve }; // Use the spread operator to create a new object with the updated age

      }
      return collection; // Return the original object if the id doesn't match
    });

    setcollectionsData(updatedCollection);
  };



  return (
    <>
      {/* <CustomLoader isLoading={loader} /> */}
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div" style={{ maxWidth: "1400px" }}>
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  NFT Collection Management
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div" style={{ maxWidth: "1400px" }}>
              <div className="flex-div-sm">
                <h1>NFT Collection Management</h1>

                <ul className="btn-lister">

                  <li>
                 
                      <div
                        className="reg-btn mr-2 mb-2"
                        onClick={() => {
                          setchecked(1)
                          // handleFilter('Approved')
                        }}
                        style={{
                          background: checked == 1 ? "#9e75ff" : "white",
                          color: checked == 1 ? "white" : "#9e75ff",
                          border: "1px solid #9e75ff",
                          cursor: "pointer"
                        }}


                      >Approved </div>
                      <div
                        onClick={() => {
                          setchecked(2)
                          // handleFilter('Unapproved')

                        }}
                        style={{
                          background: checked == 2 ? "#9e75ff" : "white",
                          color: checked == 2 ? "white" : "#9e75ff",
                          border: "1px solid #9e75ff",
                          cursor: "pointer"

                        }}
                        className="reg-btn mr-2 mb-2"

                      >Unapproved </div>
                      <div
                        onClick={() => {
                          setchecked(null)
                          setsearchedString('')

                        }}
                        style={{
                          background: "white",
                          color: "#9e75ff",
                          border: "1px solid #9e75ff",
                          cursor: "pointer"

                        }}
                        className="reg-btn mr-2 mb-2"

                      >Reset </div>


                 
                  </li>
                  <li>
                    <div className="search-pnl">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />

                      </Form>
                    </div>
                  </li>
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "nft collection management" && right.isAdd
                  ) && (localStorage.getItem('accountType') == 'Partner' ? (localStorage.getItem('isAddCollection') == 'true' ? <li>
                    <Link to="/AddNewCollection" className="reg-btn">
                      <i className="fa fa-plus"></i> Add New Collection
                    </Link>
                  </li> : <></>) :
                    <li>
                      <Link to="/AddNewCollection" className="reg-btn">
                        <i className="fa fa-plus"></i> Add New Collection
                      </Link>
                    </li>
                    )}
                </ul>
              </div>
            </div>
            <div className="collection-container">
              <div className="full-div">
                <ul className="collection-list">
                  {!loader ? (collectionsData?.length > 0 ? (
                    <>
                      {collectionsData?.map((colData) => (
                        <li key={colData.id}>
                          <NFTCollectionPost
                            dataAfterDelete={dataAfterDelete}
                            colData={colData}
                            dataAfterApprove={dataAfterApprove}
                          />
                        </li>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "5%",
                      }}
                    >
                      <p>No Record Found</p>
                    </div>
                  )) : <CustomLoader isLoading={loader} />}
                </ul>
              </div>
              <div
                className="full-div text-center pagination-container"
              >
                <Pagination
                  totalItems={totalItems}
                  itemsPerPage={12}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
                {/* <Link to="/Collection" className='reg-btn big'>View More</Link> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default NFTCollectionManagement;
