import axios from "axios";

// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const API_URL = 'https://onecm-apis.onecannabismarketinc.com/'
export const WareHouse_API_URL = 'https://onecm-warehouse.onecannabismarketinc.com/api/middleware/'
// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const MAX_FILE_SIZE_BYTES =52428800

export const http = {
  setAuthorizationHeader: () => {
    const accessToken = localStorage.getItem("Token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers.Authorization = "Bearer " + accessToken;
  },
  request(config = {}) {
    return axios.request(config);
  },
  get(url, config = {}) {
    return axios.get(url, config);
  },
  post(url, data = {}, config = {}) {
    return axios.post(url, data, config);
  },
  put(url, data = {}, config = {}) {
    return axios.put(url, data, config);
  },
  patch(url, data = {}, config = {}) {
    return axios.patch(url, data, config);
  },
  delete(url, config = {}) {
    return axios.delete(url, config);
  },
  pageSize: 999999,
};


export const colors={
mainColor:'#ad79f7'
}