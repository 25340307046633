import { MAX_FILE_SIZE_BYTES } from "../constant"
import { toast } from "react-toastify";



  const CheckFileSize=(size)=>{
    if(size>MAX_FILE_SIZE_BYTES){
        toast.error('Max File Size 50 MB')
        return true
      }
  }
  export default CheckFileSize