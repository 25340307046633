import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Dropdown, Button } from 'react-bootstrap';
import Sidebar from '../Components/Sidebar';
import Header from '../Components/header';
import { handleGetRole } from '../shared/api';
import axios from 'axios';
import axiosApi from '../axiosApi';
import { API_URL } from '../constant';
import { toast } from 'react-toastify';
function UpdateUserRole() {
    const [selectedRole, setselectedRole] = useState({ id: null, name: "" })
    const [roles, setroles] = useState([])
    const [updateRole, setUpdateRole] = useState("")
    const [reloErr, setreloErr] = useState("")
    const [slectedRoleERR, setslectedRoleERR] = useState("")
    const [loader, setloader] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        handleGetRole().then((res) => {

            // setselectedRole({ id: res.data.data[0].id, name: res.data.data[0].name })
            setroles(res.data.data)
        }).catch((error) => { })
    }, [])

    const handleUpdateRoleName = (e) => {
        e.preventDefault();
        setloader(true)
        let temp = 0;
        if (!updateRole) {
            setreloErr("Update Role Name required")

            temp++;
        }
        if (!selectedRole.id) {
            setslectedRoleERR("Please Select Role")
            temp++
        }

        if (temp > 0) {
            setloader(false)
            return
        }
        const obj = {

            "id": selectedRole.id,
            "name": updateRole

        }

        axiosApi.post(API_URL + `api/SuperAdmin/updaterole`, obj
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("Token")}`
                },
            },
        ).then((res) => {
            navigate("/SubadminManagement")
            setloader(false)
            toast.success("Role Updated Successfully")
        }).catch(() => {
            setloader(false)
        })


    }



    return (
        <>
            <Sidebar />
            <main className='dasboard-main'>
                <div className='dashboard-inner-pnl'>
                    <Header />
                    <div className='head-inner-panel'>
                        <div className='full-div'>
                            {/* BreadCrum */}
                            <Breadcrumb>
                                <Breadcrumb.Item> Admin Management  </Breadcrumb.Item>
                                <Breadcrumb.Item href="/SubadminManagement">
                                    Subadmin
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="#">
                                    Update Role
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            {/* BreadCrum */}
                        </div>
                        <div className='full-div'>
                            <h1>Update User Role</h1>
                            <div className='spacer-20'></div>
                        </div>
                        <div className='full-div'>
                            <Form onSubmit={(e) => { handleUpdateRoleName(e) }} style={{ maxWidth: '900px' }}>
                                <Form.Label>Role Name</Form.Label>

                                <Form.Select
                                    name='role'
                                    onChange={(e) => {
                                        setslectedRoleERR("");
                                        setselectedRole({ id: e.target.value, name: selectedRole?.name });
                                    }}
                                    className='form-control' aria-label="Default select example">
                                    <option value='' disabled selected>Choose Role</option>

                                    {roles.map((roleData) => (
                                        <option value={roleData.id} key={roleData.id} >{roleData.name}</option>
                                    ))}
                                </Form.Select>

                                {slectedRoleERR && <p className='error-msg'>{slectedRoleERR}</p>}
                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Role NAME</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Role Name" />
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Update Role</Form.Label>
                                    <Form.Control value={updateRole} onChange={(e) => { setreloErr(""); setUpdateRole(e.target.value.trimStart()) }} type="text" placeholder="Enter Role Name" />
                                    {reloErr && <p className='error-msg'>{reloErr}</p>}
                                </Form.Group>
                                <div className='spacer-40'></div>
                                <ul className='half-list'>
                                    <li>
                                        {loader ? (<>
                                            <Button  className='reg-btn w-100 big'>
                                                <span className="spinner-grow spinner-grow-sm" id="loading-span"  role="status" aria-hidden="true"></span>
                                                <span className="sr-only">Loading...</span>
                                            </Button>
                                        </>)

                                            : (<Button type='submit' className='reg-btn w-100 big'>
                                                Save
                                            </Button> 

                                            )}
                                    </li>
                                    <li>
                                        <Button onClick={() => navigate(-1)} className='reg-btn w-100 trans big'>Cancel</Button>
                                        {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                                    </li>
                                </ul>
                            </Form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default UpdateUserRole; 