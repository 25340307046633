import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Button } from 'react-bootstrap';
import Sidebar from '../Components/Sidebar';
import Header from '../Components/header';
import { API_URL } from '../constant';
import axios from 'axios';
import axiosApi from '../axiosApi';
import { toast } from 'react-toastify';
import CheckFileSize from './controllers';
function PushNotification() {

  const [loader, setloader] = useState(false)
  const [uploadImage, setuploadImage] = useState(null);
  const [notificationData, setnotificationData] = useState({
    title: null,
    image: null,
    description: null,
  });
  const [erroMessage, seterroMessage] = useState({
    title: null,
    image: null,
    description: null,
  });



  const navigate = useNavigate();

  const [uploadImages, setuploadImages] = useState({
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);

  const handleFeaturedImageUpload = (event) => {
    const file = event.target.files[0];

    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        image: "Please upload a PNG or JPG image",
      }));
      setImagePreview(null);
      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnotificationData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setuploadImages((prev) => ({
        ...prev,
        image: file,
      }));
      seterroMessage((prevState) => ({
        ...prevState,
        image: null,
      }));

      // Create a URL for the image preview
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };




  const handlePushNotification = (e) => {
    e.preventDefault()
    setloader(true)
    if (!notificationData.title) {
      seterroMessage((prevState) => ({
        ...prevState,
        title: "Add notification title",
      }));
      setloader(false)
      return
    }
    else {
      seterroMessage((prevState) => ({
        ...prevState,
        title: null,
      }));
    }
    if (!notificationData.description) {
      seterroMessage((prevState) => ({
        ...prevState,
        description: "Add notification description",
      }));
      setloader(false)
      return
    }
    else {
      seterroMessage((prevState) => ({
        ...prevState,
        description: null,
      }));
    }
    if (!notificationData.image) {
      seterroMessage((prevState) => ({
        ...prevState,
        image: "Add notification Image",
      }));
      setloader(false)
      return
    }
    else {
      seterroMessage((prevState) => ({
        ...prevState,
        image: null,
      }));
    }

    const bodyFormdata = new FormData();

    bodyFormdata.append("Tittle", notificationData.title);
    bodyFormdata.append("Description", notificationData.description);
    bodyFormdata.append("Image", notificationData.image);

    axios
      .post(API_URL + `api/Nft/SendNotification`, bodyFormdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        toast.success("Notification Sent Successfully");
        setloader(false);
        setnotificationData({
          title: '',
          image: null,
          description: '',
        });
        setImagePreview(null)
        navigate("/SendNotification");
      })
      .catch((error) => {
        setloader(false);
        toast.error("Something went wrong!");
        navigate("/SendNotification");
      });


  }

  return (
    <>
      <Sidebar />
      <main className='dasboard-main'>
        <div className='dashboard-inner-pnl'>
          <Header />
          <div className='head-inner-panel'>
            <div className='full-div'>
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management  </Breadcrumb.Item>

                <Breadcrumb.Item href="#">
                  Send Push Notification
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className='full-div'>
              <h1>Send Notification</h1>
              <div className='spacer-20'></div>
            </div>
            <div className='full-div'>
              <Form onSubmit={(e) => handlePushNotification(e)} style={{ maxWidth: '900px' }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Title</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setnotificationData((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Enter Notification Title"
                    value={notificationData.title}
                  />
                  {erroMessage.title && (
                    <p className="error-msg">{erroMessage.title}</p>
                  )}
                </Form.Group>



                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Description</Form.Label>
                  <Form.Control
                    as="textarea"  // Set 'as' prop to 'textarea'
                    rows={3}

                    placeholder="Enter Notification Description"
                    onChange={(e) => {
                      setnotificationData((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    maxLength={300}
                    value={notificationData.description}
                  />
                  {erroMessage.description && (
                    <p className="error-msg">{erroMessage.description}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="reg-btn big w-100 br-rad"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFeaturedImageUpload}
                  />
                  {erroMessage.image && (
                    <p className="error-msg">{erroMessage.image}</p>
                  )}
                </Form.Group>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    className="uploaded-image-preview"
                    width={300}
                    height={250}
                  />
                )}
                <div className='spacer-40'></div>

                <ul className='half-list'>
                  <li>

                    {loader ? (<>
                      <Button className='reg-btn w-100 big'>
                        <span className="spinner-grow spinner-grow-sm" id="loading-span" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                      </Button>
                    </>) : (
                      <Button type='submit' className='reg-btn w-100 big'>
                        Send
                      </Button>
                    )}
                  </li>
                  <li>
                    <Button onClick={() => navigate(-1)} className='reg-btn w-100 trans big'>Cancel

                    </Button>

                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default PushNotification; 